import { createApi } from '@reduxjs/toolkit/query/react';
import WebApiRoutes from '@config/Routes/WebApiRoutes';
import BaseSetting from '@config/BaseSetting'
import BaseQueryFactory from 'bundles/libs/ServiceClient/BaseQueryFactory';

const fetchBaseQuery = new BaseQueryFactory({}).create()

export const reportingDashboardApi = createApi({
  reducerPath: 'reportingDashboardApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    getProtectionCoverage: builder.query({
      query: (payload: { partner_ids?: string[] }) => ({
        url: WebApiRoutes.protectionCoveragePath(),
        method: 'POST',
        body: { ...payload },
        headers: { 'X-CSRF-Token': BaseSetting.csrfToken },
      }),
      transformResponse: (response: any) => response.data,
    }),
    getSeatUsage: builder.query({
      query: (payload: { partner_ids?: string[] }) => ({
        url: WebApiRoutes.seatUsagePath(),
        method: 'POST',
        body: { ...payload },
        headers: { 'X-CSRF-Token': BaseSetting.csrfToken },
      }),
      transformResponse: (response: any) => response.data,
    }),
    getOrganizations: builder.query({
      query: (payload: { start_date?: string; end_date?: string }) => ({
        url: WebApiRoutes.organizationPath(payload),
      }),
      transformResponse: (response: any) => response.data,
    }),
    getAccounts: builder.query({
      query: (payload: { start_date?: string; end_date?: string }) => ({
        url: WebApiRoutes.accountPath(payload),
      }),
      transformResponse: (response: any) => response.data,
    }),
    getJobStatus: builder.query({
      query: (payload: { partner_ids?: string[], organization_ids?: string[] }) => ({
        url: WebApiRoutes.backgroundJobStatusPath(),
        method: 'POST',
        body: { ...payload },
        headers: { 'X-CSRF-Token': BaseSetting.csrfToken },
      }),
      transformResponse: (response: any) => response.data,
    }),
    getBilling: builder.query<any, void>({
      query: () => ({
        url: WebApiRoutes.billingPath(),
      }),
    }),
    getActiveCharts: builder.query<any, void>({
      query: () => ({
        url: WebApiRoutes.activeChartsPath(),
      }),
      transformResponse: (response: any) => response.data,
    }),
  }),
});

export const {
  useGetProtectionCoverageQuery,
  useGetSeatUsageQuery,
  useGetOrganizationsQuery,
  useGetAccountsQuery,
  useGetJobStatusQuery,
  useGetBillingQuery,
  useGetActiveChartsQuery,
} = reportingDashboardApi;